<template>
  <div class="ffme_structure_organigramme">
    <Loader v-if="!mounted" :loading="true"></Loader>

    <div v-else class="organigramme">
      <div class="orga__title">
        Organigramme <span>{{ structureName }}</span>
      </div>
      <div v-if="isLoadingOrganigramme">
        Chargement des utilisateurs de l'organigramme
      </div>
      <div v-else-if="utilisateurs.length">
        <userCard
          v-for="(user, index) in utilisateurs"
          :key="index"
          :user="user"
          @onSelected="onSelected"
          @onUpdated="onUpdated"
          @onDeleted="onDeleted"
          :readOnly="readOnly"
        />
      </div>
      <div v-else>Aucun membre défini dans l'organigramme</div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/LoadingIndicator";
import userCard from "@/components/Structure/Organigramme/userCard.vue";
import { mapActions } from "vuex";

export default {
  name: "Organigramme",

  components: { Loader, userCard },

  data: () => ({
    mounted: false,
    utilisateurs: [],
    structure: null,
    isLoadingOrganigramme: true,
  }),

  async created() {
    this.setData();

    this.mounted = true;
  },

  computed: {
    structureName() {
      if (this.structure) {
        return this.structure.NomStructure;
      }
      return "";
    },
  },

  methods: {
    ...mapActions("structure", [
      "getStructureByID",
      "getUserOrganizationalChart",
    ]),

    onSelected(user) {
      this.$emit("onSelected", user);
    },

    async onUpdated() {
      await this.setData();
    },

    async onDeleted() {
      this.$emit("onDeleted");
    },

    async setData() {
      this.isLoadingOrganigramme = true;
      this.structure = await this.getStructureByID(this.structure_id);
      this.utilisateurs = await this.getUserOrganizationalChart({
        ID_Structure: this.structure_id,
      });
      this.isLoadingOrganigramme = false;
    },
  },

  props: {
    structure_id: {
      type: Number,
    },
    readOnly: {
      type: Boolean,
    },
    context: {
      type: Object,
    },
  },
};
</script>

<style lang="scss">
.ffme_structure_organigramme {
  min-height: 300px;
  width: 100%;

  .organigramme {
    //padding:0 20px;

    .orga__title {
      text-align: center;
      padding: 0px 0 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      span {
        font-weight: 600;
      }
    }
  }
}
</style>
