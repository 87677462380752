<template>
  <v-dialog v-if="isOpen" v-model="isOpen" persistent max-width="600px">
    <v-tabs value="slug" background-color="blue-dark" dark grow>
      <v-tabs-slider></v-tabs-slider>
      <v-tab>Créer un utilisateur</v-tab>
      <v-icon class="close-icon" @click="close">mdi-close</v-icon>
    </v-tabs>

    <div class="modal__content" v-if="mounted">
      <v-tabs-items>
        <v-card flat class="py-6 px-4">
          <ValidateForm @validated="submit" :loading="loading">
            <!-- <InputText v-model="data.Identifiant" rules="required|min:3|max:50" label="Identifiant"></InputText> -->
            <InputText
              v-model="data.Email"
              rules="required|min:3|max:50|email"
              label="Email"
            ></InputText>

            <validation-provider
              rules="required"
              v-slot="{ errors }"
              name="civilité"
            >
              <v-row align-content="center">
                <v-col cols="12" md="3" class="label_column">
                  <label for>Sexe*:</label>
                </v-col>

                <v-col cols="12" md="9">
                  <v-radio-group v-model="data.CT_EST_Civilite" row dense>
                    <v-radio label="Homme" :value="1"></v-radio>
                    <v-radio label="Femme" :value="0"></v-radio>
                  </v-radio-group>
                  <span class="input__error-message">{{ errors[0] }}</span>
                </v-col>
              </v-row>
            </validation-provider>

            <InputText
              v-model="data.CT_Nom"
              rules="required|min:2|max:250|regexname|dashCounter"
              label="Nom"
            ></InputText>
            <InputText
              v-model="data.CT_Prenom"
              rules="required|min:2|max:250|regexname"
              label="Prenom"
            ></InputText>
            <DatePicker
              v-model="data.DN_DateNaissance"
              rules="required|min:3|max:50"
              label="Date de naissance"
            ></DatePicker>

            <countrySelect
              v-model="data.ID_PaysNationalite"
              rules="required|min:0|max:50"
              label="Nationalité"
            />
            <CommuneAutocomplete
              v-model="data.DN_CommuneNaissance"
              onlyCities
              :item="data.DN_CommuneNaissance"
              rules="required"
              @onSelected="communeSelected"
              label="Commune de naissance"
            />
            <InputTelephone
              v-model="data.CT_TelephoneFixe"
              rules="required|min:0|max:50"
              label="Téléphone fixe"
            />
            <InputTelephone
              v-model="data.CT_TelephoneMobile"
              rules="min:0|max:50"
              label="Téléphone mobile"
            />
            <v-row>
              <v-col class="text-right">
                <v-btn
                  @click="close"
                  color="red"
                  class="mx-2 white--text"
                  rounded
                  >Annuler</v-btn
                >
                <v-btn
                  type="submit"
                  :loading="loading"
                  class="btn btn-primary mx-2"
                  >Créer</v-btn
                >
              </v-col>
            </v-row>
          </ValidateForm>
        </v-card>
      </v-tabs-items>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import InputText from "@/components/utils/input.vue";
// import DatePicker from "@/components/utils/datePicker.vue";
import ValidateForm from "@/components/utils/form.vue";
import countrySelect from "@/components/utils/select__countries";
import { error_notification } from "@/plugins/notifications";
import InputTelephone from "@/components/utils/inputTelephone";
import CommuneAutocomplete from "@/components/utils/adresseAutocomplete";
import { trimAndCapitalizeFirstLetter, trimAndUppercaseAllLetters } from "@/plugins/utils";
// import axios from 'axios'

export default {
  components: {
    ValidateForm,
    DatePicker: () =>
      import(
        /* webpackChunkName: "input__datepicker" */ "@/components/utils/datePicker.vue"
      ),
    InputText,
    countrySelect,
    InputTelephone,
    CommuneAutocomplete,
  },
  data: () => ({
    loading: false,
    mounted: false,
    data: {},
  }),

  async created() {
    this.setData();
    this.mounted = true;
  },

  methods: {
    ...mapActions("structure", ["updateStructureUrl", "insertStructureUrl"]),
    ...mapActions("utilisateurs", ["createAuthUser"]),
    ...mapActions("basics", ["getListTypeReseauxSociaux"]),

    setData() {
      if (this.item) {
        this.data = this.item;
        this.data.CT_Nom = trimAndUppercaseAllLetters(this.data.CT_Nom);
        this.data.CT_Prenom = trimAndCapitalizeFirstLetter(this.data.CT_Prenom);
      }

      this.data.Email = "";
      this.data.CT_EST_Civilite = null;
      this.data.ID_PaysNationalite = null;
      this.data.DN_CommuneNaissance = null;
      //this.data.DN_DateNaissance = ""
      this.data.CT_TelephoneFixe = null;
      this.data.CT_TelephoneMobile = null;
    },

    async submit(newIdentifiant) {
      this.loading = true;
      let payload = {
        user: {
          CT_Email: this.data.Email,
          CT_Prenom: this.data.CT_Prenom,
          CT_Nom: this.data.CT_Nom,
        },
        ID_Admin: this.currentUser.id,
        ID_Structure: this.currentStructure.ID_Structure,
      };

      try {
        let userID = await this.createAuthUser(payload);
        if (typeof userID === "object") {
          return error_notification(userID.message);
        } else {
          // TODO REMETTRE L'ENVOIE DE MAIL QUAND DISPO
          // await send_email({
          //   Email: "register@yopmail.com",
          //   ID_Structure: `${this.currentStructure.ID_Structure}`,
          //   Context: "register",
          //   SlugModule: "gestion_des_utilisateurs",
          // });

          this.$emit("onCreated", { id: userID, ...this.data });
        }
      } catch (e) {
        error_notification();
      }
      this.loading = false;
    },

    async communeSelected(item) {
      this.data.DN_CommuneNaissance = item[0].long_name;
      await this.getCodeInsee(item[0].long_name);
    },

    async getCodeInsee(city_name) {
      const fetch = require("node-fetch");

      (async () => {
        const response = await fetch(
          "https://api-adresse.data.gouv.fr/search/?q=" +
            city_name +
            "&type=municipality&autocomplete=0",
          {
            method: "get",
          }
        );
        const json = await response.json();
        if (json.features.length) {
          this.data.DN_CommuneNaissance_CodeInsee =
            json.features[0].properties.id;
        }
      })();

      return;
    },

    close() {
      this.$emit("onClose");
    },
  },

  computed: {
    ...mapGetters("structure", ["currentStructure"]),
    ...mapGetters("user", ["currentUser"]),
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },

    context: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.label_column {
  width: auto;
  label {
    margin-bottom: 0;
    padding-top: 5px;
    font-weight: 200;
    font-size: 0.8rem;
  }
}
.not-editable {
  font-size: 0.8rem;
  line-height: 30px;
}
input {
  border: 1px solid #dfe5f9;
  padding: 4px 5px;
  font-weight: 200;
  font-size: 0.8rem;
  width: 100%;
  max-width: 400px;

  &.is-invalid {
    border-color: red;
  }
  &:focus {
    outline: none;
  }

  &.full-width {
    max-width: 100%;
  }
}

.rounded-pill {
  padding: 4px 13px;
}

.v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}

.close-icon {
  margin-top: 10px !important;
}
</style>
